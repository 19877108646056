export const noticeConstants = {
    CREATE_REQUEST: 'NOTICE_CREATE_REQUEST',
    CREATE_SUCCESS: 'NOTICE_CREATE_SUCCESS',
    CREATE_FAILURE: 'NOTICE_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'NOTICE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'NOTICE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'NOTICE_UPDATE_FAILURE',

    GETBYID_REQUEST: 'NOTICE_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'NOTICE_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'NOTICE_GETBYID_FAILURE',

    GETALL_REQUEST: 'NOTICES_GETALL_REQUEST',
    GETALL_SUCCESS: 'NOTICES_GETALL_SUCCESS',
    GETALL_FAILURE: 'NOTICES_GETALL_FAILURE',

    DELETE_REQUEST: 'NOTICES_DELETE_REQUEST',
    DELETE_SUCCESS: 'NOTICES_DELETE_SUCCESS',
    DELETE_FAILURE: 'NOTICES_DELETE_FAILURE'    
};
