export const calendarConstants = {
    CREATE_REQUEST: 'CALENDAR_CREATE_REQUEST',
    CREATE_SUCCESS: 'CALENDAR_CREATE_SUCCESS',
    CREATE_FAILURE: 'CALENDAR_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'CALENDAR_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CALENDAR_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CALENDAR_UPDATE_FAILURE',

    GETBYID_REQUEST: 'CALENDAR_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'CALENDAR_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'CALENDAR_GETBYID_FAILURE',

    GETALL_REQUEST: 'CALENDARS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CALENDARS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CALENDARS_GETALL_FAILURE',

    DELETE_REQUEST: 'CALENDARS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CALENDARS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CALENDARS_DELETE_FAILURE'    
};
