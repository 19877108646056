export const sliderConstants = {
    CREATE_REQUEST: 'SLIDER_CREATE_REQUEST',
    CREATE_SUCCESS: 'SLIDER_CREATE_SUCCESS',
    CREATE_FAILURE: 'SLIDER_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'SLIDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SLIDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SLIDER_UPDATE_FAILURE',

    GETBYID_REQUEST: 'SLIDER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'SLIDER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'SLIDER_GETBYID_FAILURE',

    GETALL_REQUEST: 'SLIDERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'SLIDERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'SLIDERS_GETALL_FAILURE',

    DELETE_REQUEST: 'SLIDERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'SLIDERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'SLIDERS_DELETE_FAILURE'    
};
